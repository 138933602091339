@media (min-height: 748px) {
  .responsive .rightSidebarTabs .sidebarTab .tabLabel .tabTitle {
    display: block;
  }
}

/* Short */
@media (max-height: 480px) {

  #page-controls #page-controls-expand-wrapper #page-controls-expand-button {
    top: 30%;
  }

  #page-controls-filter-badge {
    top: calc(30% - 12px);
  }

}

/* Small, Medium, and Large */
@media (max-width: 1394px) {
  :root {
    --content-width: calc(100vw - var(--nav-rail-width) - var(--page-controls-width) - 18px);
    --code-toolbar-height: 32px;
    --code-content-top: calc(var(--code-toolbar-top) + var(--code-toolbar-height) + 9px);
  }

  #page-grid {
    grid-template-areas:
    "rail search-bar search-bar"
    "rail warnings warnings"
    "rail breadcrumbs-bar breadcrumbs-bar"
    "rail page-controls page-content"
    "rail footer footer";
    grid-template-columns: var(--nav-rail-width) var(--page-controls-width) auto;
  }

  #page-grid.page-controls-collapsed {
    --content-width: calc(100vw - var(--nav-rail-width) - var(--page-controls-width) - 18px);
  }

  #page-extra-space {
    display: none;
  }

  .no-page-controls {
    --page-controls-width: 8px;
    --page-controls-height: 34px;
    --page-content-top: calc(var(--breadcrumbs-bar-top) + var(--breadcrumbs-bar-height) + var(--page-controls-height));
    --content-width: calc(100vw - var(--nav-rail-width) - 16px);
  }

  #search-bar #search-bar-wrapper .content-area, #footer #footerContent {
    width: calc(100vw - var(--nav-rail-width) - 66px);
    padding-left: 24px;
  }

  #search-bar #search-bar-wrapper .content-area {
    margin-left: 0;
  }

  #breadcrumbs-bar-content {
    width: 100%;
  }

  #footer #footerContent {
    margin-left: var(--nav-rail-width);
  }

  #toolbar .menuText {
    display: none;
  }

  #toolbarArea, #toolbar {
    height: 32px;
  }

  .toolbarButton {
    padding-left: 4px;
    padding-right: 4px;
  }
}

/* Small and Medium */
@media (max-width: 912px) {
  :root {
    --nav-rail-width: 44px;
    --page-controls-width: calc(100vw - var(--nav-rail-width));
    --page-controls-height: 34px;
    --content-width: calc(100vw - var(--nav-rail-width));
    --code-content-top: calc(var(--code-toolbar-top) + 9px);
  }

  #page-grid {
    grid-template-areas: "rail search-bar"
        "rail warnings"
        "rail breadcrumbs-bar"
        "rail page-controls"
        "rail page-content"
        "rail footer";
    grid-template-columns: var(--nav-rail-width) auto;
  }

  #page-controls {
    z-index: 4;
  }

  #page-grid.page-controls-collapsed {
    --page-controls-width: 0;
  }

  #page-controls-wrapper .page-control-widget {
    width: var(--page-controls-width);
  }

  #page-controls div#page-controls-wrapper {
    padding: 0;
  }

  #indexPage #page-controls-wrapper {
    width: var(--page-controls-width);
    display: block;
  }

  #searchResults #page-controls-wrapper {
    width: var(--page-controls-width);
    margin-right: 0;
    display: block;
    padding: 16px;
  }

  #page-grid #page-controls #page-controls-expand-wrapper {
    height: 24px;
    width: auto;
    top: calc(var(--search-bar-height) + var(--breadcrumbs-bar-height) - 12px);
    left: var(--nav-rail-width);
    right: 0;
  }

  #page-controls #page-controls-expand-wrapper #page-controls-expand-button {
    top: 0;
    left: calc(50% - 12px);
    border: 1px solid #FFF;
  }

  #page-controls #page-controls-expand-wrapper:hover #page-controls-expand-button, #page-controls #page-controls-expand-wrapper #page-controls-expand-button:focus {
    border-color: var(--accent-color-light);
  }

  #page-controls #page-controls-expand-wrapper #page-controls-expand-button .material-icons:after, #page-grid.page-controls-collapsed #page-controls #page-controls-expand-wrapper #page-controls-expand-button .material-icons:after {
    content: "keyboard_arrow_down";
  }

  #page-controls #page-controls-expand-wrapper #page-controls-expand-button + #page-controls-edge {
    height: 2px;
    width: 100%;
    top: 10px;
    left: 0;
    border-width: 0 0 2px 0;
  }

  #page-controls-filter-badge {
    display: flex;
    left: calc(50% + 4px);
    top: -8px;
  }

  #rail > .items > .item > .item-label > .item-label-text {
    display: none;
  }

  #gcLogo .font-icon, #gcLogoSpacer .font-icon {
    font-size: 26px;
  }

  #page-controls-wrapper .page-control-widget#tocWidget {
    width: 100%;
    min-height: 34px;
  }

  #page-controls-wrapper.fixed {
    overflow-y: visible;
    height: 38px;
    display: block;
    width: calc(var(--page-controls-width));
    position: fixed;
    top: auto;
  }

  #page-controls-wrapper .page-control-widget:first-child, #page-controls-wrapper .page-control-widget:last-child {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  .content-area {
    margin-left: 0;
  }

  .responsiveContentPanelOpen {
    width: 100%;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .responsiveContentPanelOpenWrapper {
    display: block;
    height: 34px;
    background-color: #FFF;
  }

  #loginExisting {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  #loginExisting #usernameArea, #loginExisting #passwordArea {
    float: none;
    width: auto;
  }

  #loginButton {
    margin-right: 0;
  }

  .homeHalfPage {
    flex-direction: column;
  }

  div#top {
    background-size: 100%;
  }

  #footer #footerContent {
    margin-left: calc(var(--nav-rail-width) + 8px);
  }

  .titleChevronLink {
    display: none;
  }

  #purchase {
    display: none;
  }

  #search-bar-wrapper {
    padding-left: 8px;
  }

  #breadcrumbs .breadcrumb:first-of-type, #breadcrumbs .separator:first-of-type, #breadcrumbs .breadcrumb:last-of-type {
    display: flex;
  }

  #breadcrumbs-bar #breadcrumbs-bar-wrapper {
    padding-left: 8px;
  }

  #breadcrumbs * {
    display: none;
  }

  #footer #googleTranslateWidget {
    margin-right: 16px;
  }

  #toolbarArea {
    display: none;
  }

  #responsiveContentPanelDrawer #page-controls-wrapper .page-control-widget.fixed {
    position: static;
  }

  #responsiveContentPanelDrawer .scrollable.page-control-widget {
    max-height: none;
  }

  .responsive .responsiveTable,
  .responsive .responsiveTable table {
    box-shadow: none;
  }

  .responsive .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .responsive .responsiveTable tr:not(.tableDetailRow) {
    border-top: 1px solid #DDD;
  }

  .responsive .responsiveTable tr:not(.tableDetailRow) td:first-child {
    margin-top: 8px;
  }

  .responsive .responsiveTable tr.tableDetailRow td:first-child {
    margin-top: -8px;
  }

  .responsive .responsiveTable tr td:last-child {
    margin-bottom: 8px;
  }

  .responsive .responsiveTable td {
    border: none;
    display: block;
  }

  .responsive .responsiveTable td:before {
    padding-right: 8px;
  }

  .responsive .responsiveTable td[data-column-name]:before {
    font-weight: bold;
    content: attr(data-column-name) ": ";
    float: left;
  }

  .responsive .responsiveTable tr.tableDetailRow td:before {
    font-weight: bold;
    content: "Comment: ";
    float: left;
    width: 100%;
  }

  .responsive .responsiveTable tr.tableDetailRow td {
    padding: 4px 8px 4px 16px !important;
  }
}

/* Styling in-between medium and small to handle footer issues */
@media (max-width: 645px) {
  .bigOnly {
    display: none;
  }
}

/* Small */
@media (max-width: 480px) {
  :root {
    --breadcrumbs-bar-height: 80px;
  }

  #breadcrumbs {
    flex-wrap: wrap;
  }

  #breadcrumbs .breadcrumb:last-of-type {
    flex: 1 1 100%;
  }

  .litem_number, .defitem_number {
    min-width: 0;
  }

  .litem_content, .defitem_content {
    margin-left: 8px;
  }

  #pageTitle .link {
    display: block;
  }

  #pageTitle .titleNumber {
    padding-right: 0.28em;
  }

  #pageTitle .titleNumber + .titleTitle {
    padding-left: 0;
  }

  div.graphicalLinkRow {
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: column;
  }

  figure.graphicalFigure {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
  }

  figure.graphicalFigure .material-icons,
  figure.graphicalFigure .font-icon {
    font-size: 24px;
    height: 24px;
    width: 24px;
  }

  figure.graphicalFigure figcaption.graphicalCaption {
    font-size: 18px;
    margin: 8px;
  }

  .level .wideTable,
  .level .level .wideTable,
  .level .level .level .wideTable,
  .level .level .level .level .wideTable,
  .level .level .level .level .level .wideTable,
  .level .level .level .level .level .level .wideTable {
    margin-left: -6px;
  }

}